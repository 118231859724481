import {
  OrganizationDetailsDto,
  OrganizationDto,
  OrganizationInputDto,
} from '@portals/shared/admin/OrganizationDto';

import { request } from './request';

export async function fetchOrganizations(): Promise<OrganizationDto[]> {
  const response = await request<OrganizationDto[]>({
    url: 'organizations',
  });
  return response.data;
}

export async function fetchOrganization(
  id: string,
): Promise<OrganizationDetailsDto> {
  const response = await request<OrganizationDetailsDto>({
    url: `organizations/${id}`,
  });
  if (response.data) {
    return response.data;
  }
  throw new Error('Could not find Organization');
}

export async function removeUserFromOrganization(
  userId: string,
  organizationId: string,
): Promise<void> {
  await request({
    url: `organizations/${organizationId}/users/${userId}`,
    method: 'DELETE',
  });
}

export async function addMissingUsersToOrganization(
  organizationId: string,
): Promise<void> {
  await request({
    url: `organizations/${organizationId}/users`,
    method: 'PATCH',
  });
}

export async function deleteOrganization(
  organizationId: string,
): Promise<void> {
  await request({
    url: `organizations/${organizationId}`,
    method: 'DELETE',
  });
}

export async function saveOrganization(
  organization: OrganizationInputDto,
): Promise<void> {
  await request({
    url: `organizations/`,
    method: 'POST',
    data: organization,
  });
}
