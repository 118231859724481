



































import {
  TableColumn,
  TableComponent,
} from '@dnb-open-banking/vue-table-component';
import { OrganizationDto } from '@portals/shared/admin/OrganizationDto';
import { format } from 'date-fns';
import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';
import Loader from '@/components/Loader.vue';
import { fetchOrganizations } from '@/service/organizationService';

type Data = {
  organizations: OrganizationDto[];
  loading: boolean;
  create: boolean;
};

export default Vue.extend({
  name: 'list-organizations',
  components: {
    Loader,
    DnbIcon,
    TableComponent,
    TableColumn,
  },
  data(): Data {
    return {
      organizations: [],
      loading: false,
      create: false,
    };
  },
  mounted() {
    this.loadOrganizations();
  },
  methods: {
    async loadOrganizations() {
      this.loading = true;
      this.organizations = await fetchOrganizations();
      this.loading = false;
    },
    onRowClick(row: { data: OrganizationDto }) {
      this.$router.push(`/organizations/${row.data.id}`);
    },
    dateFormatter(date: Date) {
      if (!date) return 'N/A';
      return format(date, 'DD.MM.YYYY');
    },
  },
});
